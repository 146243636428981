import { LanguageType } from "../../context/LanguageContext"

export const textDataEN: LanguageType = {
	language: 'en',
	theme: 'Theme',
	presentation: {
		name: 'Gabriel Viana',
		role: ['< Full Stack Developer />',  '< Front End Developer />', '< Back End Developer />'],
		navigation: {
			about: 'About',
			projects: 'Projects',
			contact: 'Contact'
		}
	},

	about: {
		title: 'About me',
		description: {
			t1: 'As a Full Stack Developer, I am passionate about technology and always motivated to create innovative solutions that challenge my knowledge and skills. I constantly work to enhance my knowledge and professional development to provide high-quality solutions to my clients.',
			t2: "I keep myself updated with emerging technologies and industry trends to provide innovative and efficient solutions. That's why I am always looking for challenges and opportunities to expand my knowledge and skills.",
			t3: 'My goal is to provide high-quality technology solutions and meet the specific needs of each project I work on.'
		},
		navigation: {
			contact: 'Contact'
		}
	},

	technologies: {
		title: 'Skills and Technologies',
	},

	projects: {
		title: 'Personal Projects',
	},

	contact: {
		title: 'Contact',
		subtitle: 'Feel free to contact me!',
		buttons: {
			email: 'Email',
			phone: 'Phone',
			cv: 'Download CV'
		}
	}
}