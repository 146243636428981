export default{
    title: 'dark',

    colors:{
        primary: '#8A0067',
        // primary: '#0053C0',
        secundary: '#94A3B8',

        background: '#292332',
        // background: '#1E2235',
        backgroundOpacity: '#8A006720',
        // backgroundOpacity: '#0053C015',
        text: '#FFFFFF',
        buttonText: '#fff',

        shadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',

        backgroundpresentation: 'radial-gradient(46.97% 53.54% at 50% 46.46%, rgba(204, 0, 153, 0.4) 0%, rgba(0, 1, 2, 0) 100%), #292332',
        // backgroundpresentation: 'radial-gradient(46.97% 53.54% at 50% 46.46%, rgba(0, 80, 223, 0.4) 0%, rgba(0, 1, 2, 0) 100%), #1E2235', 
        backgroundpresentation780: 'radial-gradient(97% 53.54% at 50% 46.46%, rgba(204, 0, 153, 0.4) 0%, rgba(0, 1, 2, 0) 100%), linear-gradient(0deg, #292332, #292332), rgb(255, 255, 255)',
        // backgroundpresentation780: 'radial-gradient(97% 53.54% at 50% 46.46%, rgba(0, 80, 223, 0.4) 0%, rgba(0, 1, 2, 0) 100%), linear-gradient(0deg, #1E2235, #1E2235), rgb(255, 255, 255)',
    }
}