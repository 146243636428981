const technologiesData = [
    {
        "id": 1,
        "name": "React JS"
    },
    {
        "id": 2,
        "name": "Next JS"
    },
    {
        "id": 3,
        "name": "Nest JS"
    },
    {
        "id": 4,
        "name": "Typescript"
    },
    {
        "id": 5,
        "name": "Node JS"
    },
    {
        "id": 6,
        "name": "ASP.NET Core 5"
    },
    {
        "id": 7,
        "name": "Spring Boot"
    },
    {
        "id": 8,
        "name": "Golang"
    },
    {
        "id": 9,
        "name": "AWS"
    },
    {
        "id": 10,
        "name": "MLOps"
    },
    {
        "id": 11,
        "name": "PostgreSQL"
    },
    {
        "id": 12,
        "name": "Agile"
    },
    {
        "id": 13,
        "name": "Git"
    }
]

export default technologiesData;