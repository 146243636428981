export default{
    title: 'light',

    colors:{
        primary: '#8A0067',
        // primary: '#0053C0',
        secundary: '#636466',

        background: '#fff',
        backgroundOpacity: '#8A006720',
        // backgroundOpacity: '#0053C015',
        text: '#222222',
        buttonText: '#ccc',

        shadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',

        backgroundpresentation: 'radial-gradient(46.97% 53.54% at 50% 46.46%, rgba(204,204,204,1) 0%, rgba(255,255,255,1) 100%), #fff',
        backgroundpresentation780: 'radial-gradient(97% 53.54% at 50% 46.46%, rgba(204,204,204,1) 0%, rgba(255,255,255,1) 100%), linear-gradient(0deg, #ccc, #ccc)',
    }
}